import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'; // eslint-disable-line import/no-extraneous-dependencies
import thunk from 'redux-thunk';
import * as API from './services/forumConversation.service';
import rootReducer from './rootReducer';

/* local constants */
const initialStore = {};

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const peristedState = loadFromLocalStorage();

/**
 * Configure Redux store
 * Enable redux dev tools
 * Setup redux observables as async library
 *
 * @function
 * @name configureStore
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const configureStore = () => {
  const store = createStore(
    rootReducer,
    peristedState,
    // initialStore,
    composeWithDevTools(applyMiddleware(thunk.withExtraArgument({ API })))
  );
  store.subscribe(() => saveToLocalStorage(store.getState()));
  return store;
};

export default configureStore;
