import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const getRouteMissingPermission = (userPermissions, requiredPermissions) => {
  if (!requiredPermissions || !requiredPermissions.length) return null;
  else if (!userPermissions || !userPermissions.length)
    return requiredPermissions[0];
  else {
    let permissionCheckStatus = requiredPermissions.map((permission) => {
      return userPermissions.some(
        (item) => item.permission.genericName === permission
      );
    });
    let missingPermissions = requiredPermissions.filter((permission, index) => {
      let position = index;
      let isRequired = permissionCheckStatus.some((item, index) => {
        return position === index && !item;
      });
      return isRequired;
    });
    if (missingPermissions && missingPermissions.length)
      return missingPermissions[0];
  }

  return null;
};

function PrivateRoute({
  component: Component,
  permissions = [],
  ...routeProps
}) {
  const token = localStorage.getItem('token');
  const userIsLoggedIn = token && token.length;
  const user = JSON.parse(localStorage.getItem('currentUser'));
  const userPermissions = user && user.permission;
  const missingPermission = getRouteMissingPermission(
    userPermissions,
    permissions
  );

  if (!userIsLoggedIn) return <Redirect to={{ pathname: '/login' }} />;

  if (user && user.changePassword === true)
    return <Redirect to={{ pathname: '/change-password' }} />;
  else if (missingPermission)
    return (
      <Redirect
        to={{
          pathname: `/503/${missingPermission}`,
          state: { modal: true },
          missingPermission: missingPermission,
        }}
      />
    );

  return Component ? (
    <Route {...routeProps} render={(props) => <Component {...props} />} />
  ) : (
    <Route {...routeProps} />
  );
}

export default PrivateRoute;
