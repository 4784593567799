export const SET_FORUMS_ACTIVE_TAB = 'SET_FORUMS_ACTIVE_TAB';
export const SET_PREVIEWED_FORUM_ID = 'SET_PREVIEWED_FORUM_ID';

export const GET_USER_FORUMS_START = 'GET_USER_FORUMS_START';
export const GET_USER_FORUMS_SUCCESS = 'GET_USER_FORUMS_SUCCESS';
export const GET_USER_FORUMS_ERROR = 'GET_USER_FORUMS_ERROR';

export const GET_OTHER_FORUMS_START = 'GET_OTHER_FORUMS_START';
export const GET_OTHER_FORUMS_SUCCESS = 'GET_OTHER_FORUMS_SUCCESS';
export const GET_OTHER_FORUMS_ERROR = 'GET_OTHER_FORUMS_ERROR';

export const SET_USER_FORUM_DETAILS = 'SET_USER_FORUM_DETAILS';
export const SET_OTHER_FORUM_DETAILS = 'SET_OTHER_FORUM_DETAILS';

export const SUBSCRIBE_TO_FORUM = 'SUBSCRIBE_TO_FORUM';
export const UNSUBSCRIBE_FROM_FORUM = 'UNSUBSCRIBE_FROM_FORUM';

export const SET_CURRENT_FORUM_ID = 'SET_CURRENT_FORUM_ID';

export const FORUM_SOCKET_CONNECTION_CHANGED =
  'FORUM_SOCKET_CONNECTION_CHANGED';
export const FORUM_MESSAGES_FETCHED = 'FORUM_MESSAGES_FETCHED';
export const FORUM_MEMBERS_FETCHED = 'FORUM_MEMBERS_FETCHED';
export const ADD_MESSAGE_TO_FORUM = 'ADD_MESSAGE_TO_FORUM';
export const UPDATE_NEW_MESSAGES_COUNT = 'UPDATE_NEW_MESSAGES_COUNT';
export const RESET_NEW_MESSAGES_COUNT = 'RESET_NEW_MESSAGES_COUNT';
export const UPDATE_MESSAGE_DETAILS = 'UPDATE_MESSAGE_DETAILS';
export const DELETE_MESSAGE_FROM_FORUM = 'DELETE_MESSAGE_FROM_FORUM';
