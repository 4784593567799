import React from 'react';
import { HashRouter, Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from './utilities/PrivateRoute';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import './App.scss';
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login'));
const ChangePassword = React.lazy(() =>
  import('./views/ChangePassword/changePassword')
);
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Page503Modal = React.lazy(() => import('./views/Pages/Page503Modal'));
export const store = configureStore();

class Router extends React.Component {
  previousLocation = this.props.location;

  componentWillUpdate(nextProps) {
    let { location } = this.props;
    const shouldUpdatePreviousLocation =
      nextProps.history.action !== 'POP' &&
      (!location.state || !location.state.modal);

    if (shouldUpdatePreviousLocation)
      this.previousLocation = this.props.location;
  }

  render() {
    const { location } = this.props;
    const isModal = !!(
      location &&
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    ); // not initial render

    return (
      <React.Fragment>
        <React.Suspense fallback={loading()}>
          <Switch>
            {' '}
            {/* location={isModal ? this.previousLocation : location} */}
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/change-password"
              name="Change Password"
              render={(props) => <ChangePassword {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/503/:permission"
              name="Not Allowed"
              component={Page503Modal}
            />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
          {/* <Switch>
            <Route path="/" component={Home} exact/>
            <Route path="/login" component={Login} />
          </Switch> */}

          {isModal && (
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <PrivateRoute path="/" name="Home" component={DefaultLayout} />
            </Switch>
          )}
        </React.Suspense>
      </React.Fragment>
    );
  }
}

const WrappedRouter = withRouter(Router);

function App() {
  return (
    <Provider store={store}>
      <HashRouter>
        <WrappedRouter />
      </HashRouter>
    </Provider>
  );
}

export default App;
