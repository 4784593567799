import React from 'react';
import * as actions from './actions';
import * as API from '../../../services/forumConversation.service';
// import {appSelectors} from '../../duck';
import { socket, forumSelectors } from '../duck';
import { message } from 'antd';

export const setActiveTab = actions.setActiveTab;
export const setSocketConnectionStatus = actions.setSocketConnectionStatus;
export const addMessageToForum = actions.addMessageToForum;
export const setPreviewedForumId = actions.setPreviewedForumId;
export const setCurrentForumId = actions.setCurrentForumId;
export const deleteMessageFromForum = actions.deleteMessageFromForum;
export const resetNewMessagesCount = actions.resetNewMessagesCount;

const token = localStorage.getItem('token');

/**
 * an operation that gets forums that a user has joined
 * @function
 * @name getUserForums
 * @version 0.1.0
 * @since 0.1.0
 */
export const getUserForums = (page = 1) => (dispatch, getState) => {
  // const state = getState();
  // const token = appSelectors.getToken(state);

  dispatch(actions.getUserForumsStart());

  API.getUserForums(page, token)
    .then((res) => {
      let responseData = res.data.forums;
      dispatch(actions.getUserForumsSuccess(responseData));
      // dispatch(actions.setMainForumId(responseData));
      responseData.forEach((forum) => {
        if (forum.type === 'main-forum')
          dispatch(actions.setCurrentForumId(forum._id));

        socket.emit('join-forum', { forum: forum._id });
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.getUserForumsError(err));
    });
};

/**
 * an operation that gets forums that a user hasn't joined
 * @function
 * @name getOtherForums
 * @version 0.1.0
 * @since 0.1.0
 */
export const getOtherForums = (page = 1) => (dispatch, getState) => {
  // const state = getState();
  // const token = appSelectors.getToken(state);

  dispatch(actions.getOtherForumsStart());

  API.getOtherForums(page, token)
    .then((res) => {
      let responseData = res.data;
      dispatch(actions.getOtherForumsSuccess(responseData));
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.getOtherForumsError(err));
    });
};

/**
 * an operation that subscribes a user to a forum given forumId
 * @function
 * @name joinForum
 * @version 0.1.0
 * @since 0.1.0
 */
export const joinForum = (forumId, history) => async (dispatch, getState) => {
  const state = getState();
  // const token = appSelectors.getToken(state);

  dispatch(actions.setOtherForumDetails(forumId, { joining: true }));

  API.subscribeToForum({ forum: forumId }, token)
    .then((_) => {
      dispatch(actions.setOtherForumDetails(forumId, { joining: false }));
      dispatch(actions.subscribeToForum(forumId));
      const socketConnectionIsSet = forumSelectors.getSocketConnectionStatus(
        state
      );

      if (socketConnectionIsSet) {
        socket.emit('join-forum', { forum: forumId });
      }
      history.replace(`/forums/${forumId}`);
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.setOtherForumDetails(forumId, { joining: false }));
    });
};

/**
 * an operation that unsubscribes a user from a forum given forumId
 * @function
 * @name leaveForum
 * @version 0.1.0
 * @since 0.1.0
 */
export const leaveForum = (subscriptionId, forumId, history) => async (
  dispatch,
  getState
) => {
  const state = getState();
  // const token = appSelectors.getToken(state);
  const currentForum = forumSelectors.getCurrentForum(state);

  dispatch(actions.setUserForumDetails(forumId, { leaving: true }));

  API.unsubscribeFromForum({ subscriptionId }, token)
    .then((_) => {
      dispatch(actions.setUserForumDetails(forumId, { leaving: false }));
      dispatch(actions.unsubscribeFromForum(forumId));
      history.replace('/forums');

      if (currentForum && currentForum.title)
        message.success(
          <span>
            Unsubscribed from <strong>{currentForum.title}</strong>
          </span>
        );
      else message.success('Unsubscribed successfully');
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.setUserForumDetails(forumId, { leaving: false }));
    });
};

/**
 * an operation that gets messages for a given forum
 * @function
 * @name getForumMessages
 * @version 0.1.0
 * @since 0.1.0
 */
export const getForumMessages = (forumId, page = 1) => (dispatch, getState) => {
  // const state = getState();
  // const token = appSelectors.getToken(state);

  dispatch(actions.setUserForumDetails(forumId, { fetchingMessages: true }));

  API.getForumMessages(forumId, page, token)
    .then((res) => {
      let messages = res.data;
      dispatch(
        actions.setUserForumDetails(forumId, {
          messagesFetched: true,
          fetchingMessages: false,
          messages,
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.setUserForumDetails(forumId, { fetchingMessages: false })
      );
    });
};

/**
 * @function
 * @name sendForumMessage
 * @description an operation that sends messages to a given forum
 * @version 0.1.0
 * @since 0.1.0
 */
export const sendForumMessage = (messagePayload) => (dispatch, getState) => {
  const token = localStorage.getItem('token');
  const { forum, messageKey } = messagePayload;
  dispatch(
    actions.addMessageToForum(forum, { ...messagePayload, pending: true })
  );

  API.sendForumMessage(messagePayload, token)
    .then((response) => {
      dispatch(
        actions.updateMessageDetails(forum, messagePayload.messageKey, {
          ...messagePayload,
          _id: response.data._id,
          pending: false,
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.deleteMessageFromForum(forum, messageKey));
      message.error('Failed to send message');
    });
};

/**
 * an operation that updates a forum that has received new message
 * @function
 * @name updateForumOnNewMessage
 * @version 0.1.0
 * @since 0.1.0
 */
export const updateForumOnNewMessage = (forumId, messagePayload) => (
  dispatch,
  getState
) => {
  dispatch(actions.addMessageToForum(forumId, messagePayload));
  dispatch(actions.updateNewMessagesCount(forumId));
};

/**
 * an operation that sends messages to a given forum
 * @function
 * @name fetchForumMessages
 * @version 0.1.0
 * @since 0.1.0
 */
export const fetchForumMessages = (forumId, page = 1) => (
  dispatch,
  getState
) => {
  // const state = getState();
  // const token = appSelectors.getToken(state);

  dispatch(actions.setUserForumDetails(forumId, { fetchingMessages: true }));

  API.getForumMessages(forumId, page, token)
    .then((response) => {
      dispatch(
        actions.setUserForumDetails(forumId, {
          fetchingMessages: false,
          messagesFetched: true,
        })
      );
      dispatch(actions.forumMessagesFetched(forumId, response.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.setUserForumDetails(forumId, { fetchingMessages: false })
      );
    });
};

/**
 * an operation that fetchs members belonging to a given forum
 * @function
 * @name fetchForumMembers
 * @version 0.1.0
 * @since 0.1.0
 */
export const fetchForumMembers = (forumId) => (dispatch, getState) => {
  API.getForumMembers(token, forumId)
    .then((response) => {
      dispatch(actions.forumMembersFetched(forumId, response.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * @function
 * @name deleteForumMessage
 * @description an operation that deletes a messages from a forum
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteForumMessage = (messagePayload) => (dispatch, getState) => {
  const { forum, _id: messageId, userId } = messagePayload;

  dispatch(actions.updateMessageDetails(forum, messageId, { deleting: true }));
  API.deleteForumMessage({ forum, messageId, userId }, token)
    .then((response) => {
      dispatch(actions.deleteMessageFromForum(forum, messageId));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.updateMessageDetails(forum, messageId, { deleting: false })
      );
      message.error("Message couldn't be deleted");
    });
};
