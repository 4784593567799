import { get, find, filter } from 'lodash';

/**
 * gets forums active tab
 * @function
 * @name getActiveTab
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {String} - forum active tab
 */
export const getActiveTab = (state) => get(state, 'forums.activeTab', '');

/**
 * gets the previewed forum
 * @function
 * @name getPreviewedForum
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {String} - previewed forum
 */

/**
 * gets access token from redux state object
 * @function
 * @name getAuthUser
 * @params {Object} - redux state
 * @returns {Object} - authenticated user
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAuthUser = (state) => get(state, 'user.data.userProfile', null);

export const getPreviewedForum = (state) => {
  const otherForums = get(state, 'forums.other.forums', []);
  const previewedForumId = get(state, 'forums.other.previewedForumId', null);

  if (otherForums && previewedForumId)
    return otherForums.find(({ _id }) => _id === previewedForumId);
  else return null;
};

/**
 * gets user forums
 * @function
 * @name getUserForums
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Array} - user forums
 */
export const getUserForums = (state) => {
  const userForums = get(state, 'forums.forUser.forums', null);
  if (!userForums) return null;

  return userForums;
};

/**
 * gets loading state for user forums
 * @function
 * @name getUserForumsLoading
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Boolean} - loading state for user forums
 */
export const getUserForumsLoading = (state) =>
  get(state, 'forums.forUser.loading', false);

/**
 * gets error fetching user forums
 * @function
 * @name getUserForumsError
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Boolean} - fetching error for user forums
 */
export const getUserForumsError = (state) =>
  get(state, 'forums.forUser.error', null);

/**
 * gets forums user hasn't joined
 * @function
 * @name getOtherForums
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Array} - user forums
 */
export const getOtherForums = (state) =>
  get(state, 'forums.other.forums', null);

/**
 * gets loading state for other forums
 * @function
 * @name getOtherForumsLoading
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Boolean} - loading state for other forums
 */
export const getOtherForumsLoading = (state) =>
  get(state, 'forums.other.loading', false);

/**
 * gets error fetching other forums
 * @function
 * @name getOtherForumsError
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Boolean} - fetching error for other forums
 */
export const getOtherForumsError = (state) =>
  get(state, 'forums.other.error', null);

/**
 * gets the forum currently being viewed
 * @function
 * @name getCurrentForum
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - forum being viewed
 */
export const getCurrentForumId = (state) => {
  const currentForumId = get(state, 'forums.currentForumId', '');

  return currentForumId;
};

/**
 * gets the forum currently being viewed
 * @function
 * @name getCurrentForum
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - forum being viewed
 */
export const getCurrentForum = (state) => {
  const userForums = get(state, 'forums.forUser.forums', []);
  const currentForumId = get(state, 'forums.currentForumId', '');

  return find(userForums, ['_id', currentForumId]);
};

/**
 * gets the messages for the forum currently being viewed
 * @function
 * @name getCurrentForumMessages
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Array} - messages for current forum
 */
export const getCurrentForumMessages = (state) => {
  const userForums = get(state, 'forums.forUser.forums', []);
  const currentForumId = get(state, 'forums.currentForumId', '');
  const currentForum = find(userForums, ['_id', currentForumId]);

  return currentForum && currentForum.messages ? currentForum.messages : [];
};

/**
 * gets members for the forum currently being viewed
 * @function
 * @name getCurrentForumMembers
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Array} - members for current forum
 */
export const getCurrentForumMembers = (state) => {
  const userForums = get(state, 'forums.forUser.forums', []);
  const currentForumId = get(state, 'forums.currentForumId', '');
  const currentForum = find(userForums, ['_id', currentForumId]);

  return currentForum && currentForum.members ? currentForum.members : [];
};

/**
 * gets the number of unread messages in the forum currently being viewed
 * @function
 * @name getUnreadMessagesCount * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Array} - messages for current forum
 */
export const getUnreadMessagesCount = (state) => {
  const userForums = get(state, 'forums.forUser.forums', []);
  const currentForumId = get(state, 'forums.currentForumId', '');
  const currentForum = find(userForums, ['_id', currentForumId]);
  let unreadMessagesCount = 0;
  currentForum &&
    currentForum.messages &&
    currentForum.messages.forEach((message) => {
      if (message.unread) unreadMessagesCount = unreadMessagesCount + 1;
    });

  return unreadMessagesCount;
};

/**
 * gets the main forum details
 * @function
 * @name getMainForum
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - main forum
 */
export const getMainForum = (state) => {
  const userForums = get(state, 'forums.forUser.forums', []);
  return find(userForums, ['type', 'main-forum']);
};

/**
 * gets the forum socket connection status
 * @function
 * @name getSocketConnectionStatus
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Boolean} - socket connection status
 */
export const getSocketConnectionStatus = (state) =>
  get(state, 'forums.connectedToSocket', false);
